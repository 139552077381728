import { addCollaboratorsToAgentSession } from "@/modules/sessions/requests";
import { useTeamState } from "@/modules/team/states";
import ZAvatarGradient from "@/shared/components/avatarGradient";
import { ZButton } from "@/shared/components/button";
import ModalCloseIcon from "@/shared/icons/modal-close";
import collaboratorsImg from "@/shared/images/collaborators.png";
import { addNotification } from "@/shared/states/notification";
import {
  Autocomplete,
  AutocompleteItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "@nextui-org/react";
import { useStytchMember } from "@stytch/react/b2b";
import clsx from "clsx";
import { isEqual, sortBy } from "lodash";
import { XIcon } from "lucide-react";
import { useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getAgentStateActions, useAgentSessionData } from "../../states";

type Props = {
  onClose: () => void;
};

type Params = {
  id: string;
};

const ShareSessionModal = ({ onClose }: Props) => {
  const { id } = useParams() as Params;
  const autoCompleteRef = useRef<HTMLInputElement | null>(null);

  const sessionData = useAgentSessionData(id);
  const { updateAgentSessionData } = getAgentStateActions();
  const { team } = useTeamState();
  const { member: loggedInUser } = useStytchMember();

  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>(
    sessionData?.permissions.collaborators ?? [],
  );
  const [isShareSessionLoading, setIsShareSessionLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState<string>('');

  const shareWithList = useMemo(() => {
    return team.filter((teamMember) => {
      return !(
        teamMember.member_id === loggedInUser?.member_id ||
        selectedMemberIds.includes(teamMember.member_id)
      );
    });
  }, [team, loggedInUser, selectedMemberIds]);

  const filteredSelectedMemberIds = useMemo(() => {
    return selectedMemberIds.filter(
      (teamMemberId) => teamMemberId !== loggedInUser?.member_id,
    );
  }, [selectedMemberIds, loggedInUser]);

  const ownerDetails = useMemo(() => {
    return team.find(
      (teamMember) => teamMember.member_id === sessionData?.permissions.owner,
    );
  }, [team, sessionData?.permissions.owner]);

  const isOwner = useMemo(() => {
    return ownerDetails && (ownerDetails.member_id === loggedInUser?.member_id)
  }, [ownerDetails, loggedInUser]);

  const onInputChange = (value: string) => {
    // restrict special chars, digits and space as first value
    if (/^[A-Za-z][A-Za-z ]*$/.test(value) || value === "")
      setAutoCompleteInputValue(value);
  }

  const shareSessionWithSelectedMembers = async (
    selectedMemberIds: string[],
  ) => {
    try {
      setIsShareSessionLoading(true);
      const result = await addCollaboratorsToAgentSession(
        id,
        selectedMemberIds,
      );
      updateAgentSessionData(id, (result.data as any).session);
      onClose();
      return addNotification({
        type: "success",
        title: "List of Collaborators Updated Successfully",
        message:
          "Collaborators will be able to review, edit and accept responses generated in this session.",
      });
    } catch (error) {
      console.error(error);
      return addNotification({
        type: "error",
        title: "Session Share Failed",
        message: "Something went wrong, please contact support@zania.ai",
      });
    } finally {
      setIsShareSessionLoading(false);
    }
  };

  const handleSelectionChange = (memberId: string) => {
    let newSelectedMemberIds = [...selectedMemberIds];
    if (newSelectedMemberIds.includes(memberId)) {
      newSelectedMemberIds.splice(newSelectedMemberIds.indexOf(memberId), 1);
    } else {
      newSelectedMemberIds.push(memberId);
    }
    // remove corrupt values from newSelectedMemberIds and check if selected and new values are equal. if not display footer
    newSelectedMemberIds = newSelectedMemberIds.filter(Boolean);
    const areEqual = isEqual(sortBy(newSelectedMemberIds), sortBy(sessionData?.permissions.collaborators ?? []));
    setHasChanged(!areEqual);
    setSelectedMemberIds(newSelectedMemberIds.filter(Boolean));
    setAutoCompleteInputValue('');
  };

  return (
    <Modal isOpen={true} size="lg" hideCloseButton={true} onClose={onClose}>
      <ModalContent>
        <ModalHeader className="self-center">
          <button role="button" aria-label="Close" onClick={onClose}
            className="absolute appearance-none select-none top-1 right-1 rtl:left-1 rtl:right-[unset] p-2 text-foreground-500 
        rounded-full hover:bg-default-100 active:bg-default-200 tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 
        data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 text-[16px] wei"
            type="button">

            <ModalCloseIcon />
          </button>
          <div className="flex flex-col items-center justify-center gap-2">
            <p className="text-[18px] font-semibold">
              Share "{sessionData?.name}"
            </p>
          </div>
        </ModalHeader>
        <ModalBody className="self-center items-center w-full">
          <Autocomplete
            label={<div onClick={(ev) => ev.preventDefault()}>Add Collaborators</div>}
            placeholder="Search and add team members"
            defaultItems={shareWithList}
            inputValue={autoCompleteInputValue}
            onInputChange={(value: string) => onInputChange(value)}
            labelPlacement="outside"
            allowsCustomValue={true}
            classNames={{
              base: "w-full",
              selectorButton: "hidden",
            }}
            disableSelectorIconRotation
            selectorIcon={false}
            onSelectionChange={(key) => handleSelectionChange(key as string)}
          >
            {(recipient) => (
              <AutocompleteItem className="p-3" key={`${recipient.member_id}`}>
                {recipient.name}
              </AutocompleteItem>
            )}
          </Autocomplete>
          <ul className="w-full h-[368px] overflow-y-auto px-2 py-3">
            {/* only show owner's name on the list to other collaborators */}
            {ownerDetails && !isOwner && (
              <li
                key={sessionData?.permissions.owner}
                className="p-2 rounded-lg overflow-hidden flex items-center justify-between group hover:bg-[#f4f4f5]"
              >
                <span className="flex gap-2 items-center">
                  <ZAvatarGradient name={ownerDetails.name} size={36} />
                  <p>{ownerDetails.name}</p>
                </span>
                <p className="text-tiny font-light">Owner</p>
              </li>
            )}
            {
              filteredSelectedMemberIds.map((memberId) => {
                const selectedUser = team.find(
                  (teamMember) => teamMember.member_id === memberId,
                );
                return (
                  selectedUser && (
                    <li
                      key={memberId}
                      className="p-2 rounded-lg overflow-hidden flex items-center justify-between group hover:bg-[#f4f4f5]"
                    >
                      <span className="flex gap-2 items-center">
                        <ZAvatarGradient name={selectedUser.name} size={36} />
                        <p>{selectedUser.name}</p>
                      </span>
                      <button
                        type="button"
                        className="hidden group-hover:block transition-transform delay-200 ease-in-out cursor-pointer w-min h-min"
                        aria-label={`Remove ${selectedUser.name} from shared with user list`}
                        onClick={() =>
                          handleSelectionChange(selectedUser.member_id)
                        }
                      >
                        <XIcon size={16} />
                      </button>
                    </li>
                  )
                );
              })
            }
            {
              !filteredSelectedMemberIds.length && isOwner && (
                <div className="w-full h-full flex flex-col gap-6 items-center justify-center">
                  <img
                    src={collaboratorsImg}
                    className="h-[240px] w-240px"
                    alt="collaborators"
                  />
                  <p className="text-[#777780] text-sm w-72 text-center">
                    Collaborators can review, edit and accept responses generated
                    in this session
                  </p>
                </div>
              )
            }
          </ul >
        </ModalBody >
        <ModalFooter
          className={clsx(hasChanged ? "visible" : "invisible", "transition")}
        >
          <ZButton color="primary" variant="bordered" onClick={onClose} className="px-3 py-1 h-auto text-sm">
            Cancel
          </ZButton>
          <ZButton
            color="primary"
            className="px-3 py-1 h-auto text-sm"
            isDisabled={isShareSessionLoading}
            onClick={() => shareSessionWithSelectedMembers(selectedMemberIds)}
          >
            {isShareSessionLoading && <Spinner size="sm" color="white" />}
            Share
          </ZButton>
        </ModalFooter>
      </ModalContent >
    </Modal >
  );
};

export default ShareSessionModal;