export enum AgentSocketEvents {
	CONTROL_EDIT = "shared:doc:edited",
	CONTROL_UPDATED = "shared:doc:updated",
	CONTROL_APPROVED_NOTIFICATION = "doc:controls:approved:notification",
	CONTROL_APPROVED = "doc:controls:approved",
}
export interface ControlEditPayload {
	file_url: string;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	control: any;
	control_id: string | undefined;
	agent_session_id: string;
	agent_step_id: string;
	token: string;
}
export interface ControlUpdatePayload {
	file_url: string;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	control: any;
	control_id: string;
	agent_session_id: string;
	agent_step_id: string;
	user_id: string;
}