import { getZaniaSocket } from "@/infra/sockets";
import { AgentSocketEvents } from "../types/events";
import { getSessionJWT } from "@/infra/stytch";
import { getAgentData, getAgentStateActions } from "../states";
import { AgentSessionStepType, AgentSessionType } from "@/modules/sessions/types";
import { AGENT_TYPES } from "../types";

export const useControlApproval = (agentId: string) => {
  const socket = getZaniaSocket();

  const emitApproval = async (controlIds: string | string[]) => {
    try {
      const agentData = getAgentData(agentId);
      if (!agentData) return;

      const stepData = agentData.stepData;
      const editStepData = stepData?.find(
        (step) => step.type === AgentSessionStepType.EDIT_RESPONSE
      );

      const token = await getSessionJWT();

      socket.emit(
        AgentSocketEvents.CONTROL_APPROVED,
        {
          file_url: editStepData?.data?.url,
          agent_session_id: agentId,
          agent_session_step_id: editStepData?.id,
          control_ids: Array.isArray(controlIds) ? controlIds : [controlIds],
          task: agentData.agentType === AGENT_TYPES.GAP_ASSESSMENT
							? agentData.sessionData.type === AgentSessionType.SOC2_GAP_TYPE1
								? "soc2_type1"
								: agentData.sessionData.type === AgentSessionType.SOC2_GAP_TYPE2
								? "soc2_type2"
								: "soc2_type2_audit"
							: agentData.agentType === AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT
							? "multi_file_vendor_assessment"
							: "nist_csf_2_risk_assessment",
        
          token
        },
        (error: Error) => {
          if (error) {
            console.error("Socket emit error, Failed to approve response:", error);
          }
        }
      );

    } catch (error) {
      console.error("Error in emitApproval:", error);
      throw error;
    }
  };

  return { emitApproval };
};

//son