import { get, patch, post, put } from "@/infra/rest";
import {
  AddCollaboratorsToAgentSessionRequest,
  AgentSessionCreateRequest,
  AgentSessionCreateResponse,
  AgentSessionResponse,
  AgentSessionStepUpdateRequest,
  AgentSessionStepUpdateResponse,
  AgentSessionsRequest,
  AgentSessionsResponse,
} from "../types";


export const getPaginatedAgentSessions = async (
  data: AgentSessionsRequest,
): Promise<AgentSessionsResponse> => {
  const response = await post<AgentSessionsRequest, AgentSessionsResponse>({
    url: "/v0/agents/sessions",
    data,
  });
  return response;
};

export const getAgentSession = async (
  sessionId: string,
): Promise<AgentSessionResponse> => {
  const response = await get<AgentSessionResponse>({
    url: `/v0/agents/session/${sessionId}`,
  });
  return response;
};

export const createAgentSession = async (data: AgentSessionCreateRequest) => {
  const response = await post<
    AgentSessionCreateRequest,
    AgentSessionCreateResponse
  >({
    url: "/v0/agents/session",
    data,
  });
  return response;
};

export const updateAgentSessionStep = async (
  data: AgentSessionStepUpdateRequest,
) => {
  data.updated_at = new Date().toISOString();
  const response = await put<
    AgentSessionStepUpdateRequest,
    AgentSessionStepUpdateResponse
  >({
    url: `/v0/agents/step/${data.id}`,
    data,
  });
  return response;
};

export const addCollaboratorsToAgentSession = async (
  sessionId: string,
  collaboratorIds: string[],
): Promise<AddCollaboratorsToAgentSessionRequest> => {
  const response = await patch<AddCollaboratorsToAgentSessionRequest>({
    url: `/v0/agents/session/${sessionId}`,
    config: {
      data: {
        all: false,
        collaborators: collaboratorIds,
      },
    },
  });

  return response;
};


export const getAgentSessionControls = async (
  sessionId: string,
): Promise<any> => {
  const response = await get<any>({
    url: `/v0/agents/session/${sessionId}/controls`,
  });
  return response;
};
